.payment-setup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f7fafc;
}

.payment-setup-form {
  background: white;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 480px;
}

.payment-setup-form h2 {
  text-align: center;
  color: #2d3748;
  margin-bottom: 24px;
  font-size: 24px;
  font-weight: 600;
}

.setup-description {
  color: #4a5568;
  text-align: center;
  margin-bottom: 32px;
  line-height: 1.6;
}

.error-message {
  margin-bottom: 24px;
  padding: 12px;
  background-color: #fff5f5;
  border: 1px solid #feb2b2;
  border-radius: 8px;
  color: #c53030;
  font-size: 14px;
  text-align: center;
}

.payment-methods {
  margin-bottom: 24px;
}

.payment-method-item {
  border: 2px solid #e2e8f0;
  border-radius: 8px;
  padding: 16px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.payment-method-item.active {
  border-color: #4299e1;
  background-color: #ebf8ff;
}

.payment-method-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 12px;
}

.payment-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.card-brands {
  display: flex;
  gap: 8px;
  margin-top: 8px;
}

.card-brands img {
  height: 20px;
  object-fit: contain;
}

.card-element-container {
  padding: 16px;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #f8fafc;
  margin-bottom: 24px;
}

.secure-badge {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-bottom: 24px;
  color: #718096;
  font-size: 14px;
}

.secure-badge img {
  height: 24px;
  object-fit: contain;
}

.submit-button {
  width: 100%;
  padding: 14px;
  background-color: #4299e1;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-button:hover:not(:disabled) {
  background-color: #3182ce;
  transform: translateY(-1px);
}

.submit-button:disabled {
  background-color: #a0aec0;
  cursor: not-allowed;
}

.submit-button.processing {
  position: relative;
  color: transparent;
}

.submit-button.processing::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 20px;
  height: 20px;
  margin: -10px 0 0 -10px;
  border: 2px solid rgba(255, 255, 255, 0.2);
  border-top-color: white;
  border-radius: 50%;
  animation: spin 0.8s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
} 