.parking-detail-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  min-height: 100vh;
  background: #f5f5f5;
  padding-top: 40px;
}

.detail-content {
  background: white;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  position: relative;
}

.info-section {
  margin-top: 40px;
  padding: 24px 24px 24px 24px;
}

.header-section {
  position: fixed;
  top: 46px;
  left: 210px;
  z-index: 1;
}

.back-button {
  color: #333;
  transition: all 0.3s ease;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
  background: white !important;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.back-button:hover {
  color: #1890ff;
  border-color: #1890ff !important;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.price-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  padding-bottom: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.price-info {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.location-title {
  margin: 0;
  font-size: 28px;
  color: #1a1a1a;
  font-weight: 600;
  line-height: 1.2;
}

.price {
  font-size: 32px;
  color: #f5222d;
  margin: 0;
  font-weight: 600;
  line-height: 1;
}

.status-section {
  display: flex;
  align-items: center;
  gap: 16px;
}

.status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.status.available {
  background-color: #e6f7ff;
  color: #1890ff;
  border: 1px solid #91d5ff;
}

.status.occupied {
  background-color: #fff1f0;
  color: #ff4d4f;
  border: 1px solid #ffa39e;
}

.status.reserved {
  background-color: #fff7e6;
  color: #fa8c16;
  border: 1px solid #ffd591;
}

.action-buttons {
  display: flex;
  gap: 12px;
}

.use-button {
  background: #1890ff;
  border-color: #1890ff;
}

.use-button:hover {
  background: #40a9ff;
  border-color: #40a9ff;
}

.reserve-button {
  border-color: #1890ff;
  color: #1890ff;
}

.reserve-button:hover {
  background: #e6f7ff;
}

.info-items {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.info-item {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
}

.info-item label {
  color: #999;
  min-width: 70px;
}

.closing-warning {
  color: #ff4d4f;
  font-weight: bold;
  margin-left: 8px;
  font-size: 13px;
  animation: blink 2s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.timeline-container {
  margin: 24px 0;
  padding: 20px;
  background: #fafafa;
  border-radius: 8px;
}

.reservations-section {
  margin: 24px 0;
}

.reservations-section h3 {
  margin-bottom: 16px;
  color: #1a1a1a;
  font-size: 18px;
}

.reservations-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.reservation-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: #fafafa;
  border-radius: 6px;
  margin-bottom: 8px;
}

.reservation-item:last-child {
  margin-bottom: 0;
}

.reservation-item .time {
  color: #1a1a1a;
  font-weight: 500;
}

.reservation-item .status {
  font-size: 13px;
}

.description-section {
  margin: 24px 0;
}

.description-section h3 {
  margin-bottom: 16px;
  color: #1a1a1a;
  font-size: 18px;
}

.description-section p {
  color: #666;
  line-height: 1.6;
  margin: 0;
}

.map-section {
  margin: 24px 0;
}

.map-section h3 {
  margin-bottom: 16px;
  color: #1a1a1a;
  font-size: 18px;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .parking-detail-container {
    padding: 0;
  }

  .detail-content {
    border-radius: 0;
  }

  .info-section {
    padding: 16px 16px 16px 16px;
  }

  .header-section {
    top: 40px;
    left: -2px;
  }

  .back-button {
    width: 36px;
    height: 36px;
  }

  .price-section {
    align-items: center;
  }

  .location-title {
    font-size: 24px;
  }

  .price {
    font-size: 28px;
  }

  .info-items {
    grid-template-columns: 1fr;
    gap: 12px;
  }

  .action-buttons {
    flex-direction: column;
    width: 100%;
  }

  .action-buttons button {
    width: 100%;
  }

  .timeline-container {
    margin: 16px 0;
    padding: 16px;
  }
}

/* 小屏手机适配 */
@media screen and (max-width: 375px) {
  .info-section {
    padding: 12px 12px 12px 48px;
  }

  .header-section {
    top: 40px;
    left: -2px;
  }

  .back-button {
    width: 32px;
    height: 32px;
  }

  .location-title {
    font-size: 20px;
  }

  .price {
    font-size: 24px;
  }

  .status {
    font-size: 12px;
    padding: 3px 8px;
  }

  .info-item {
    font-size: 13px;
  }

  .timeline-container {
    padding: 12px;
  }
} 