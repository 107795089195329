.parking-usage {
  max-width: 600px;
  margin: 2rem auto;
  padding: 2rem;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.parking-usage h2 {
  text-align: center;
  color: #333;
  margin-bottom: 2rem;
}

.parking-info {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 6px;
  margin-bottom: 2rem;
}

.parking-info h3 {
  margin: 0;
  color: #2c3e50;
}

.parking-info p {
  margin: 0.5rem 0 0;
  color: #666;
}

.start-section,
.usage-section,
.payment-section {
  text-align: center;
  padding: 2rem 0;
}

.timer,
.cost-estimate {
  margin-bottom: 2rem;
}

.time-display,
.cost-display {
  font-size: 2.5rem;
  font-weight: bold;
  color: #2c3e50;
  margin: 1rem 0;
}

.payment-details {
  background-color: #f8f9fa;
  padding: 1.5rem;
  border-radius: 6px;
  margin: 1rem 0 2rem;
}

.payment-details p {
  margin: 0.5rem 0;
  font-size: 1.1rem;
}

button {
  padding: 1rem 2rem;
  font-size: 1.1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.start-button {
  background-color: #4CAF50;
  color: white;
}

.start-button:hover {
  background-color: #45a049;
}

.end-button {
  background-color: #f44336;
  color: white;
}

.end-button:hover {
  background-color: #da190b;
}

.payment-button {
  background-color: #2196F3;
  color: white;
}

.payment-button:hover {
  background-color: #1976D2;
}

.insufficient-balance-alert {
  background-color: #fff3cd;
  border: 1px solid #ffeeba;
  border-radius: 6px;
  padding: 1.5rem;
  margin: 1rem 0 2rem;
  text-align: center;
}

.insufficient-balance-alert .alert-icon {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}

.insufficient-balance-alert h4 {
  color: #856404;
  margin: 0.5rem 0;
  font-size: 1.2rem;
}

.insufficient-balance-alert p {
  color: #856404;
  margin-bottom: 1.5rem;
}

.insufficient-balance-alert .alert-actions {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

.top-up-button {
  background-color: #28a745;
  color: white;
}

.top-up-button:hover {
  background-color: #218838;
}

.cancel-button {
  background-color: #6c757d;
  color: white;
}

.cancel-button:hover {
  background-color: #5a6268;
}

.loading {
  text-align: center;
  padding: 2rem;
  color: #666;
}

.error {
  color: #f44336;
  text-align: center;
  padding: 2rem;
}

@media (max-width: 600px) {
  .parking-usage {
    margin: 1rem;
    padding: 1rem;
  }

  .time-display,
  .cost-display {
    font-size: 2rem;
  }

  button {
    width: 100%;
    margin-bottom: 1rem;
  }
} 