/* 桌面端样式 */
@media screen and (min-width: 769px) {
  .sidebar {
    width: 60px;
    background-color: #1e1e1e;
    position: fixed;
    left: 0;
    top: 0;
    height: 100%;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1000;
    padding-top: 60px;
  }

  .sidebar.active {
    width: 200px;
  }
}

/* 导航菜单样式 */
.sidebar nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.sidebar nav ul li {
  margin: 0;
  padding: 0;
}

/* 链接样式 */
.sidebar-link {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  color: #fff;
  text-decoration: none;
  transition: all 0.3s ease;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
}

.sidebar-link:hover {
  background-color: rgba(255, 255, 255, 0.1);
  color: #1890ff;
}

.sidebar-link:hover::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 4px;
  background-color: #1890ff;
  transform: scaleY(1);
  transition: transform 0.2s ease;
}

/* 图标样式 */
.sidebar-link .icon {
  font-size: 20px;
  min-width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease;
}

.sidebar:not(.active) .sidebar-link .icon {
  transform: scale(1.2);
}

/* 文字样式 */
.sidebar-link .text {
  margin-left: 15px;
  opacity: 0;
  transition: opacity 0.2s ease;
  transform: translateX(-10px);
}

.sidebar.active .sidebar-link .text {
  opacity: 1;
  transform: translateX(0);
}

/* 切换按钮样式 */
.toggle-button {
  position: fixed;
  top: 10px;
  left: 10px;
  width: 40px;
  height: 40px;
  background-color: #1e1e1e;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1001;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.toggle-button:hover {
  background-color: #2d2d2d;
}

/* 遮罩层 */
.sidebar-overlay {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 999;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sidebar-overlay.active {
  display: block;
  opacity: 1;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .sidebar {
    position: fixed;
    top: 0;
    left: -200px;
    width: 200px;
    height: 100%;
    background-color: #1e1e1e;
    z-index: 1000;
    padding-top: 60px;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  }
  
  .sidebar.active {
    left: 0;
    width: 200px;
  }

  .sidebar-link {
    padding: 20px 20px;
  }

  .sidebar-link .icon {
    font-size: 24px;
    min-width: 28px;
  }

  .sidebar-link .text {
    opacity: 1;
    transform: translateX(0);
    font-size: 17px;
    margin-left: 16px;
    font-weight: 500;
  }

  .toggle-button {
    background-color: rgba(30, 30, 30, 0.9);
    width: 44px;
    height: 44px;
    font-size: 22px;
  }

  /* 移除对主内容区域的影响 */
  .content {
    margin-left: 0 !important;
    width: 100% !important;
  }
}

/* 小屏手机适配 */
@media screen and (max-width: 375px) {
  .sidebar {
    width: 180px;
    left: -180px;
  }

  .sidebar.active {
    width: 180px;
  }

  .sidebar-link {
    padding: 18px 18px;
  }

  .sidebar-link .icon {
    font-size: 22px;
    min-width: 26px;
  }

  .sidebar-link .text {
    font-size: 16px;
    margin-left: 15px;
    font-weight: 500;
  }

  .toggle-button {
    width: 42px;
    height: 42px;
    font-size: 20px;
  }
}

/* 点击区域扩大 */
.sidebar-link {
  position: relative;
}

.sidebar-link::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}
