/* 全局设置 */
html, body {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  background: #f5f5f5;
}

/* 主要布局容器 */
.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

/* 地图容器 */
.fullscreen-map-container {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.detail-map-container {
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
}

.fullscreen-map-wrapper {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  position: relative;
}

.parking-detail-map-wrapper {
  width: 100%;
  height: 400px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.map {
  width: 100%;
  height: 100%;
}

/* 搜索框容器 */
.map-search-container {
  position: absolute;
  top: 80px;
  left: 50%;
  transform: translateX(-50%);
  width: 100%;
  max-width: 600px;
  z-index: 99;
  padding: 0 20px;
}

/* 搜索框 */
.search-box {
  display: flex;
  width: 100%;
  height: 40px;
  background: #fff;
  border-radius: 20px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  transition: box-shadow 0.3s ease;
}

.search-box:hover {
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.15);
}

.search-input {
  flex: 1;
  height: 100%;
  padding: 0 20px;
  border: none;
  outline: none;
  font-size: 16px;
  color: #333;
}

.search-input::placeholder {
  color: #999;
}

.search-button {
  width: 80px;
  height: 100%;
  border: none;
  background: linear-gradient(45deg, #6a11cb, #2575fc);
  color: white;
  font-size: 16px;
  cursor: pointer;
  transition: background 0.3s ease;
}

.search-button:hover {
  background: linear-gradient(45deg, #2575fc, #6a11cb);
}

.suggestions-list {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  list-style: none;
  margin: 8px 0 0;
  padding: 0;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-height: 300px;
  overflow-y: auto;
  z-index: 99;
}

.suggestions-list li {
  padding: 12px 20px;
  cursor: pointer;
  border-bottom: 1px solid #eee;
  color: #333;
  transition: background-color 0.2s ease;
}

.suggestions-list li:last-child {
  border-bottom: none;
}

.suggestions-list li:hover {
  background-color: #f8f9fa;
}

/* 停车位面板 */
.parking-spots-panel {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: white;
  border-radius: 16px 16px 0 0;
  box-shadow: 0 -4px 12px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  transform: translateY(100%);
  transition: transform 0.3s ease-out, height 0.3s ease-out;
  overflow: hidden;
}

.parking-spots-panel.visible {
  transform: translateY(0);
}

.parking-spots-panel.expanded {
  height: 80vh !important;
}

.drawer-handle {
  width: 40px;
  height: 4px;
  background: #e0e0e0;
  border-radius: 2px;
  margin: 8px auto;
}

.panel-header {
  padding: 0 16px 12px;
  border-bottom: 1px solid #f0f0f0;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.panel-header h3 {
  margin: 0;
  font-size: 18px;
  color: #333;
  font-weight: 600;
}

.sort-select {
  padding: 6px 12px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  background: white;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  outline: none;
  transition: all 0.3s;
}

.sort-select:hover {
  border-color: #40a9ff;
}

/* 停车位列表 */
.parking-spots-list {
  height: calc(100% - 80px);
  overflow-y: auto;
  padding: 16px;
  -webkit-overflow-scrolling: touch;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 12px;
}

.parking-spots-list::-webkit-scrollbar {
  width: 6px;
}

.parking-spots-list::-webkit-scrollbar-track {
  background: #f5f5f5;
}

.parking-spots-list::-webkit-scrollbar-thumb {
  background: #ddd;
  border-radius: 3px;
}

.parking-spot-card {
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
  cursor: pointer;
  transition: background-color 0.3s;
}

.parking-spot-card:hover {
  background-color: #f5f5f5;
}

.spot-title {
  margin: 0 0 8px 0;
  font-size: 16px;
  color: #1a1a1a;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.spot-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.spot-detail {
  font-size: 14px;
  color: #666;
  display: flex;
  align-items: center;
}

.spot-price {
  color: #f5222d;
  font-weight: bold;
}

.spot-distance {
  color: #1890ff;
}

.spot-hours {
  color: #52c41a;
}

.spot-rating {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #faad14;
}

/* 加载状态 */
.spots-loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px 20px;
  gap: 12px;
}

.loading-spinner {
  width: 36px;
  height: 36px;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #1890ff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.spots-loading p {
  color: #666;
  font-size: 14px;
  margin: 0;
}

/* 空状态 */
.empty-state {
  text-align: center;
  padding: 32px 20px;
  color: #999;
}

.empty-state p {
  margin: 0;
  font-size: 14px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* 响应式调整 */
@media (max-width: 768px) {
  .map-search-container {
    padding: 0 10px;
  }
  
  .fullscreen-map-container {
    height: 100vh;
    border-radius: 0;
  }

  .parking-spots-panel {
    height: 60vh;
  }

  .parking-spots-list {
    grid-template-columns: 1fr;
  }
}

/* 加载和错误状态 */
.map-loading,
.map-error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 18px;
  color: #666;
}

/* 抽屉遮罩层 */
.drawer-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease, visibility 0.3s ease;
  z-index: 999;
}

.drawer-overlay.visible {
  opacity: 1;
  visibility: visible;
}

/* 调整地图控件容器 */
.map-controls-container {
  position: absolute;
  top: 20px;
  right: 20px;
  left: 20px;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.map-controls {
  position: fixed;
  top: 50%;
  right: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: 1000;
  transform: translateY(-50%);
}

.map-control-button {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: white;
  border: none;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
}

.map-control-button:hover {
  transform: scale(1.05);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
}

.map-control-button svg {
  width: 24px;
  height: 24px;
  fill: #1967D2;
}

.closing-warning {
  color: #ff4d4f;
  font-weight: bold;
  margin-left: 8px;
  font-size: 13px;
  animation: blink 2s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0.5; }
  100% { opacity: 1; }
}

.status {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
}

.status.available {
  background-color: #e6f7ff;
  color: #1890ff;
  border: 1px solid #91d5ff;
}

.status.occupied, .status.in_use {
  background-color: #fff1f0;
  color: #ff4d4f;
  border: 1px solid #ffa39e;
}

.status.reserved {
  background-color: #fff7e6;
  color: #fa8c16;
  border: 1px solid #ffd591;
}

.status.unavailable {
  background-color: #f5f5f5;
  color: #bfbfbf;
  border: 1px solid #d9d9d9;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .map-search-container {
    top: 60px;
    width: 90%;
    padding: 0 10px;
  }
  
  .user-location-btn,
  .drawer-trigger-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    position: fixed;
    right: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

  .user-location-btn {
    top: 50%;
    transform: translateY(-60px);
  }

  .drawer-trigger-btn {
    top: 50%;
    transform: translateY(10px);
  }

  .user-location-btn:hover,
  .drawer-trigger-btn:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    transform: translateY(-60px) scale(1.05);
  }

  .drawer-trigger-btn:hover {
    transform: translateY(10px) scale(1.05);
  }

  .user-location-btn svg,
  .drawer-trigger-btn svg {
    width: 24px;
    height: 24px;
  }

  .fullscreen-map-container {
    height: 100vh;
    border-radius: 0;
  }

  .parking-spots-panel {
    height: 70vh;
  }

  .parking-spots-list {
    grid-template-columns: 1fr;
    padding: 12px;
    gap: 8px;
  }

  .parking-spot-card {
    padding: 12px;
  }

  .spot-title {
    font-size: 14px;
  }

  .spot-info {
    gap: 2px;
  }

  .spot-detail {
    font-size: 12px;
  }

  .map-controls {
    right: 16px;
    gap: 16px;
  }

  .map-control-button {
    width: 48px;
    height: 48px;
  }

  .map-control-button svg {
    width: 24px;
    height: 24px;
  }
}

/* 小屏手机适配 */
@media screen and (max-width: 375px) {
  .map-search-container {
    top: 50px;
    width: 95%;
    padding: 0 5px;
  }

  .user-location-btn,
  .drawer-trigger-btn {
    width: 44px;
    height: 44px;
    right: 12px;
  }

  .user-location-btn {
    transform: translateY(-55px);
  }

  .drawer-trigger-btn {
    transform: translateY(5px);
  }

  .user-location-btn:hover {
    transform: translateY(-55px) scale(1.05);
  }

  .drawer-trigger-btn:hover {
    transform: translateY(5px) scale(1.05);
  }

  .search-box {
    height: 32px;
  }

  .search-input {
    font-size: 12px;
  }

  .search-button {
    width: 50px;
    font-size: 12px;
  }

  .parking-spots-panel {
    height: 80vh;
  }

  .panel-header h3 {
    font-size: 14px;
  }

  .sort-select {
    font-size: 12px;
    padding: 4px 8px;
  }

  .map-controls {
    right: 12px;
    gap: 12px;
  }

  .map-control-button {
    width: 44px;
    height: 44px;
  }

  .map-control-button svg {
    width: 22px;
    height: 22px;
  }
}

/* 登录按钮定位 */
.header-login-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1001;
}

/* 用户定位按钮 */
.user-location-btn {
  position: absolute;
  bottom: 140px;
  right: 20px;
  z-index: 1000;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  padding: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.user-location-btn:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.4);
  transform: translateY(-2px);
}

/* 抽屉触发按钮 */
.drawer-trigger-btn {
  position: absolute;
  bottom: 140px;
  left: 20px;
  z-index: 1000;
  background: white;
  border-radius: 8px;
  padding: 10px;
  box-shadow: 0 2px 6px rgba(0,0,0,0.3);
  cursor: pointer;
  transition: all 0.3s ease;
}

.drawer-trigger-btn:hover {
  box-shadow: 0 4px 12px rgba(0,0,0,0.4);
  transform: translateY(-2px);
}

/* Google Maps 控件样式覆盖 */
@media screen and (max-width: 768px) {
  /* 隐藏全屏按钮、地图类型切换按钮和街景小人 */
  .gm-fullscreen-control,
  .gm-style-mtc,
  .gm-svpc {
    display: none !important;
  }

  /* 调整缩放控件位置 */
  .gmnoprint.gm-bundled-control {
    top: auto !important;
    bottom: 80px !important;
  }

  /* 调整用户定位按钮和抽屉按钮的样式 */
  .user-location-btn,
  .drawer-trigger-btn {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: white;
    position: fixed;
    right: 16px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
  }

  .user-location-btn {
    top: 50%;
    transform: translateY(-60px);
  }

  .drawer-trigger-btn {
    top: 50%;
    transform: translateY(10px);
  }

  .user-location-btn:hover,
  .drawer-trigger-btn:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }

  .user-location-btn:hover {
    transform: translateY(-60px) scale(1.05);
  }

  .drawer-trigger-btn:hover {
    transform: translateY(10px) scale(1.05);
  }

  /* 调整按钮内部图标大小 */
  .user-location-btn svg,
  .drawer-trigger-btn svg {
    width: 24px;
    height: 24px;
  }
}

/* 小屏手机适配 */
@media screen and (max-width: 375px) {
  /* 调整缩放控件位置 */
  .gmnoprint.gm-bundled-control {
    bottom: 70px !important;
  }

  .user-location-btn,
  .drawer-trigger-btn {
    width: 44px;
    height: 44px;
    right: 12px;
  }

  .user-location-btn {
    transform: translateY(-55px);
  }

  .drawer-trigger-btn {
    transform: translateY(5px);
  }

  .user-location-btn:hover {
    transform: translateY(-55px) scale(1.05);
  }

  .drawer-trigger-btn:hover {
    transform: translateY(5px) scale(1.05);
  }
}
