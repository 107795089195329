.contact-us-container {
  padding: 24px;
  max-width: 1200px;
  margin: 0 auto;
}

.page-title {
  text-align: center;
  margin-bottom: 32px !important;
  color: #1890ff;
}

.contact-card,
.pricing-card,
.info-card,
.about-card {
  height: 100%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.contact-card .ant-card-head,
.pricing-card .ant-card-head,
.info-card .ant-card-head,
.about-card .ant-card-head {
  background-color: #f5f5f5;
  border-bottom: 2px solid #1890ff;
}

.ant-list-item {
  padding: 16px !important;
}

.ant-list-item .anticon {
  margin-right: 10px;
  color: #1890ff;
}

@media (max-width: 768px) {
  .contact-us-container {
    padding: 16px;
  }
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .contact-us-container {
    padding: 16px;
  }

  .page-title {
    font-size: 24px;
    margin-bottom: 24px !important;
  }

  .ant-card {
    margin-bottom: 16px;
  }

  .ant-card-head {
    padding: 12px;
  }

  .ant-card-head-title {
    font-size: 16px;
  }

  .ant-card-body {
    padding: 16px;
  }

  .ant-list-item {
    padding: 12px !important;
    font-size: 14px;
  }

  .ant-list-item .anticon {
    font-size: 18px;
  }
}

/* 小屏手机适配 */
@media screen and (max-width: 375px) {
  .contact-us-container {
    padding: 12px;
  }

  .page-title {
    font-size: 20px;
    margin-bottom: 20px !important;
  }

  .ant-card-head {
    padding: 10px;
  }

  .ant-card-head-title {
    font-size: 14px;
  }

  .ant-card-body {
    padding: 12px;
  }

  .ant-list-item {
    padding: 8px !important;
    font-size: 12px;
  }

  .ant-list-item .anticon {
    font-size: 16px;
  }
} 