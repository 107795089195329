/* 主容器样式 */
.chat-container {
  display: flex;
  height: calc(100vh - 100px);
  margin-top: 60px;
  background: var(--surface-color);
  border-radius: var(--border-radius);
  box-shadow: var(--shadow-1);
  overflow: hidden;
}

/* 侧边栏样式 */
.chat-sidebar {
  width: 240px;
  background: var(--surface-color);
  padding: 24px;
  border-right: 1px solid rgba(0, 0, 0, 0.12);
}

.chat-sidebar h2 {
  font-size: 24px;
  font-weight: 400;
  color: var(--text-primary);
  margin-bottom: 16px;
}

/* 聊天窗口主体 */
.chat-window {
  flex: 1;
  display: flex;
  flex-direction: column;
  background: var(--background-color);
}

/* 消息列表区域 */
.message-list {
  flex: 1;
  overflow-y: auto;
  padding: 24px;
  display: flex;
  flex-direction: column-reverse;
}

/* 消息气泡样式 */
.message {
  max-width: 70%;
  margin: 8px 0;
  padding: 12px 16px;
  border-radius: 12px;
  animation: messageSlide 0.3s ease-out;
}

.message.received {
  align-self: flex-start;
  background-color: #f1f3f4;
  color: var(--text-primary);
}

.message.sent {
  align-self: flex-end;
  background-color: var(--primary-color);
  color: white;
}

.message-content {
  margin-bottom: 4px;
}

.message-info {
  font-size: 12px;
  opacity: 0.7;
}

/* 发送消息区域 */
.send-message {
  padding: 16px;
  background: var(--surface-color);
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  gap: 8px;
}

.message-input {
  flex: 1;
  min-height: 36px;
  max-height: 120px;
  padding: 8px 12px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 18px;
  resize: none;
  font-family: inherit;
  font-size: 14px;
  line-height: 1.5;
  transition: all 0.3s ease;
  background: white;
}

.message-input:focus {
  outline: none;
  border-color: var(--primary-color);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.send-button {
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: var(--primary-color);
  color: white;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  transition: all 0.2s ease;
  flex-shrink: 0;
}

.send-button:hover {
  background-color: var(--primary-dark);
  transform: translateY(-1px);
}

.send-button:active {
  transform: translateY(1px);
}

.send-button svg {
  width: 16px;
  height: 16px;
  fill: currentColor;
}

@keyframes messageSlide {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* 滚动条样式 */
.message-list::-webkit-scrollbar {
  width: 8px;
}

.message-list::-webkit-scrollbar-track {
  background: transparent;
}

.message-list::-webkit-scrollbar-thumb {
  background-color: #444;
  border-radius: 4px;
}

/* 加载和错误状态 */
.loading, .error {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  color: #fff;
  font-size: 1.1rem;
}

.error {
  color: #ff4444;
}

/* 添加登出按钮样式 */
.logout-button {
  width: 100%;
  margin-top: 16px;
  background-color: #f1f3f4;
  color: var(--text-primary);
}

.logout-button:hover {
  background-color: #e8eaed;
}
