:root {
  /* 主色调 */
  --primary-color: #2c3e50;
  --secondary-color: #34495e;
  --accent-color: #3498db;
  
  /* 背景色 */
  --bg-primary: #ecf0f1;
  --bg-secondary: #ffffff;
  --bg-tertiary: #f8f9fa;
  
  /* 文字颜色 */
  --text-primary: #2c3e50;
  --text-secondary: #7f8c8d;
  --text-light: #95a5a6;
  
  /* 边框颜色 */
  --border-light: #e0e0e0;
  --border-medium: #bdc3c7;
  
  /* 阴影 */
  --shadow-sm: 0 2px 4px rgba(0, 0, 0, 0.05);
  --shadow-md: 0 4px 6px rgba(0, 0, 0, 0.07);
  --shadow-lg: 0 10px 15px rgba(0, 0, 0, 0.1);
  
  /* 圆角 */
  --radius-sm: 4px;
  --radius-md: 8px;
  --radius-lg: 12px;
  
  /* 间距 */
  --spacing-xs: 4px;
  --spacing-sm: 8px;
  --spacing-md: 16px;
  --spacing-lg: 24px;
  --spacing-xl: 32px;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Segoe UI', 'Microsoft YaHei', sans-serif;
  background-color: var(--bg-primary);
  color: var(--text-primary);
  line-height: 1.6;
}

.App {
  display: flex;
  min-height: 100vh;
  padding-top: 0;
}

.content {
  flex: 1;
  padding: 0;
  margin-left: 60px;
  width: calc(100% - 60px);
  box-sizing: border-box;
  transition: margin-left 0.3s ease, width 0.3s ease;
}

.sidebar.active ~ .content {
  margin-left: 200px;
  width: calc(100% - 200px);
}

/* 搜索栏样式 */
.search-bar {
  display: flex;
  align-items: center;
  margin: 20px;
  gap: 10px;
}

.search-bar input {
  padding: 8px 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 300px;
  font-size: 14px;
}

.search-button {
  padding: 8px 16px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.search-button:hover {
  background-color: #0056b3;
}

/* 按钮通用样式 */
button {
  padding: var(--spacing-sm) var(--spacing-md);
  background-color: var(--accent-color);
  color: white;
  border: none;
  border-radius: var(--radius-sm);
  cursor: pointer;
  font-weight: 500;
  transition: all 0.3s ease;
}

button:hover {
  background-color: #2980b9;
  transform: translateY(-1px);
  box-shadow: var(--shadow-md);
}

button:active {
  transform: translateY(0);
  box-shadow: var(--shadow-sm);
}

/* 分割线样式 */
.divider {
  height: 1px;
  background-color: var(--border-light);
  margin: var(--spacing-md) 0;
}

/* 卡片通用样式 */
.card {
  background: var(--bg-secondary);
  border-radius: var(--radius-md);
  padding: var(--spacing-lg);
  box-shadow: var(--shadow-md);
  border: 1px solid var(--border-light);
}

/* 加载状态 */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: var(--text-secondary);
}

/* 错误状态 */
.error {
  padding: var(--spacing-md);
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  color: #dc2626;
  border-radius: var(--radius-md);
  margin: var(--spacing-md) 0;
}

/* 滚动条样式 */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: var(--bg-tertiary);
}

::-webkit-scrollbar-thumb {
  background: var(--border-medium);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: var(--text-light);
}

/* 响应式设计 */
@media (max-width: 768px) {
  .content {
    margin-left: 60px;
    width: calc(100% - 60px);
  }
}


