/* Home page layout */
.home-container {
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.home-header {
  margin-bottom: 24px;
}

.home-title {
  margin-bottom: 16px;
  color: #1a1a1a;
  font-size: 24px;
}

.search-section {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.search-type {
  min-width: 120px;
}

.sort-select {
  min-width: 160px !important;
  width: auto !important;
}

/* 确保下拉选项也能完整显示 */
.ant-select-dropdown {
  min-width: 160px !important;
}

.parking-spots-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(280px, 1fr));
  gap: 24px;
  margin-bottom: 24px;
}

/* 修改链接样式 */
.parking-link {
  text-decoration: none; /* 去掉链接下划线 */
  color: inherit; /* 继承父元素的文字颜色 */
}

.parking-card {
  background: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  animation: fadeIn 0.5s ease-out;
}

.parking-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.parking-card h2 {
  margin: 0 0 10px 0;
  font-size: 18px;
  color: #333;
}

.price {
  color: #f5222d;
  font-size: 18px;
  font-weight: bold;
  margin: 8px 0;
}

.distance {
  color: #52c41a;
  margin-bottom: 8px;
}

.spot-details {
  color: #666;
  font-size: 14px;
}

.spot-details p {
  margin: 4px 0;
}

/* 添加卡片动画效果 */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 24px;
}

/* 自定义 Ant Design 分页组件样式 */
.ant-pagination {
  text-align: center;
}

.ant-pagination-item {
  border-radius: 4px;
}

.ant-pagination-item-active {
  background-color: #1890ff;
  border-color: #1890ff;
}

.ant-pagination-item-active a {
  color: #fff;
}

.ant-pagination-item:hover {
  border-color: #1890ff;
}

.ant-pagination-item:hover a {
  color: #1890ff;
}

.ant-pagination-prev:hover button,
.ant-pagination-next:hover button {
  border-color: #1890ff;
  color: #1890ff;
}

.ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
.ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
  color: #1890ff;
}

.ant-pagination-options {
  margin-left: 16px;
}

.loading-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .home-container {
    padding: 16px;
  }

  .home-header {
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
  }

  .sort-select {
    width: 100%;
  }

  .parking-spots-grid {
    grid-template-columns: 1fr;
  }
}

.rating {
  color: #faad14;
  font-size: 14px;
  font-weight: bold;
  margin: 8px 0;
  display: flex;
  align-items: center;
}

.rating::before {
  content: "★";
  margin-right: 4px;
}
