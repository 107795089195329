.auth-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
  background-image: linear-gradient(135deg, #f5f7fa 0%, #c3cfe2 100%);
}

.auth-form {
  position: relative;
  background: rgba(255, 255, 255, 0.98);
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  max-width: 100%;
  width: 400px;
  margin: 0;
}

.auth-form h2 {
  text-align: center;
  margin-bottom: 30px;
  color: #333;
  font-size: 28px;
  font-weight: 600;
}

.auth-form h3 {
  margin-bottom: 24px;
  color: #666;
  font-size: 20px;
  text-align: center;
}

.auth-form input,
.auth-form textarea {
  width: 100%;
  padding: 12px 16px;
  margin-bottom: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
  box-sizing: border-box;
}

.auth-form input:focus,
.auth-form textarea:focus {
  outline: none;
  border-color: #1890ff;
  box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
}

.auth-form textarea {
  min-height: 100px;
  resize: vertical;
}

.auth-form button {
  width: 100%;
  padding: 14px;
  background-color: #1890ff;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.auth-form button:hover {
  background-color: #40a9ff;
  transform: translateY(-1px);
}

.auth-form button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
}

.error-message {
  color: #ff4d4f;
  margin-bottom: 20px;
  text-align: center;
  padding: 10px;
  background-color: #fff1f0;
  border-radius: 6px;
  border: 1px solid #ffccc7;
}

.switch-mode,
.login-tip {
  text-align: center;
  margin-top: 24px;
  color: #666;
  font-size: 15px;
}

.switch-button {
  background: none;
  border: none;
  color: #1890ff;
  cursor: pointer;
  padding: 0 4px;
  font-size: inherit;
  font-weight: 500;
  transition: all 0.3s ease;
}

.switch-button:hover {
  color: #40a9ff;
  text-decoration: underline;
}

.field-error {
  color: #ff4d4f;
  font-size: 14px;
  margin-top: -16px;
  margin-bottom: 16px;
  padding-left: 4px;
}

.registration-choice {
  text-align: center;
}

.choice-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 24px;
  margin-bottom: 20px;
  background-color: white;
  border: 2px solid #1890ff;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  font-size: 18px;
  font-weight: 500;
  color: #1890ff;
}

.choice-button:hover {
  background-color: #e6f7ff;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(24, 144, 255, 0.15);
}

.choice-button small {
  margin-top: 8px;
  color: #666;
  font-size: 14px;
  font-weight: normal;
}

.quick-reg-note {
  display: block;
  text-align: center;
  color: #666;
  margin: -10px 0 20px;
  font-size: 14px;
  line-height: 1.5;
  padding: 0 10px;
}

.avatar-upload {
  text-align: center;
  margin-bottom: 24px;
}

.avatar-preview {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-bottom: 16px;
  object-fit: cover;
  border: 3px solid #1890ff;
  padding: 3px;
}

.avatar-upload input[type="file"] {
  width: auto;
  margin-bottom: 8px;
}

.avatar-upload small {
  display: block;
  color: #666;
  font-size: 13px;
}

.back-button {
  position: absolute;
  top: 20px;
  left: 20px;
  border: none;
  background: transparent;
  font-size: 20px;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  transition: all 0.3s ease;
  padding: 0;
  margin: 0;
  z-index: 1;
}

.back-button:hover {
  background: rgba(0, 0, 0, 0.05);
  color: #333;
}

/* 移动端适配 */
@media screen and (max-width: 768px) {
  .auth-container {
    padding: 16px;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .auth-form {
    padding: 30px;
    margin: 0;
    max-width: 100%;
    background: rgba(255, 255, 255, 0.98);
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }

  .auth-form h2 {
    font-size: 26px;
    margin-bottom: 24px;
  }

  .auth-form h3 {
    font-size: 20px;
    margin-bottom: 20px;
  }

  .auth-form input,
  .auth-form textarea {
    padding: 12px 16px;
    font-size: 16px;
    margin-bottom: 20px;
  }

  .auth-form button {
    padding: 14px;
    font-size: 16px;
  }

  .choice-button {
    padding: 20px;
    font-size: 18px;
  }

  .choice-button small {
    font-size: 14px;
    margin-top: 10px;
  }

  .avatar-preview {
    width: 90px;
    height: 90px;
  }

  .error-message {
    padding: 10px;
    font-size: 14px;
  }

  .field-error {
    font-size: 13px;
    margin-top: -16px;
    margin-bottom: 16px;
  }

  .switch-mode,
  .login-tip {
    font-size: 15px;
    margin-top: 20px;
  }
}

/* 小屏手机适配 */
@media screen and (max-width: 375px) {
  .auth-container {
    padding: 12px;
  }

  .auth-form {
    padding: 24px;
  }

  .auth-form h2 {
    font-size: 24px;
    margin-bottom: 20px;
  }

  .auth-form h3 {
    font-size: 18px;
  }

  .auth-form input,
  .auth-form textarea {
    padding: 10px 14px;
    font-size: 15px;
  }

  .auth-form button {
    padding: 12px;
    font-size: 15px;
  }

  .choice-button {
    padding: 16px;
    font-size: 16px;
  }

  .choice-button small {
    font-size: 13px;
  }

  .avatar-preview {
    width: 80px;
    height: 80px;
  }

  .error-message {
    font-size: 13px;
  }

  .field-error {
    font-size: 12px;
  }

  .switch-mode,
  .login-tip {
    font-size: 14px;
  }
}

/* Google登录相关样式 */
.social-login {
  margin-top: 20px;
  text-align: center;
}

.social-login .divider {
  display: flex;
  align-items: center;
  text-align: center;
  color: #666;
  margin: 15px 0;
}

.social-login .divider:before,
.social-login .divider:after {
  content: '';
  flex: 1;
  border-bottom: 1px solid #e0e0e0;
}

.social-login .divider span {
  padding: 0 10px;
}

.google-login-container {
  display: flex;
  justify-content: center;
  margin: 15px 0;
}

.google-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin: 0 auto 15px;
  display: block;
  border: 2px solid #e0e0e0;
}

/* 对话框样式优化 */
.field-error {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: -5px;
  margin-bottom: 8px;
  text-align: left;
}

.error-message {
  background-color: #fff2f0;
  border: 1px solid #ffccc7;
  color: #ff4d4f;
  padding: 8px 12px;
  border-radius: 4px;
  margin-bottom: 15px;
}

/* 验证码相关样式 */
.verification-code-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
}

.verification-code-container input {
  flex: 1;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  min-width: 200px;
  box-sizing: border-box;
}

.resend-button {
  white-space: nowrap;
  min-width: 100px;
  height: 40px;
  background-color: #f0f0f0;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 0 15px;
  cursor: pointer;
  transition: all 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  box-sizing: border-box;
}

.resend-button:hover:not(:disabled) {
  background-color: #e6e6e6;
}

.resend-button:disabled {
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.verified-field {
  padding: 8px 12px;
  background-color: #f6f6f6;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.verified-badge {
  background-color: #52c41a;
  color: white;
  padding: 2px 8px;
  border-radius: 10px;
  font-size: 12px;
}

.form-actions {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.form-actions button {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.form-actions .primary-button {
  background-color: #1890ff;
  color: white;
  border: none;
}

.form-actions .primary-button:hover:not(:disabled) {
  background-color: #40a9ff;
}

.form-actions .back-button {
  position: static;
  background-color: #f0f0f0;
  border: 1px solid #d9d9d9;
  color: #666;
  width: 100%;
}

.form-actions .back-button:hover {
  background-color: #e6e6e6;
}

.info-text {
  font-size: 14px;
  color: #666;
  margin: 15px 0;
}

/* 表单组样式 */
.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.error-message {
  color: #ff4d4f;
  font-size: 14px;
  margin-top: 5px;
}

input.error {
  border-color: #ff4d4f;
}

/* 确保验证码输入框不受其他样式影响 */
.verification-code-container input[type="text"] {
  margin-bottom: 0;
  height: 40px;
  padding: 8px 12px;
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  min-width: 200px;
  box-sizing: border-box;
}

.forgot-password {
  margin-top: 15px;
  text-align: right;
}

.forgot-password-button {
  background: none;
  border: none;
  color: #1890ff;
  cursor: pointer;
  font-size: 14px;
  padding: 0;
}

.forgot-password-button:hover {
  color: #40a9ff;
  text-decoration: underline;
}
