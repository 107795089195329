.profile-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 2rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.profile-header {
  margin-bottom: 2rem;
  padding-bottom: 1rem;
  border-bottom: 1px solid #eee;
}

.profile-name {
  font-size: 2rem;
  margin-bottom: 0.5rem;
  color: #333;
}

.profile-username {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 0.5rem;
}

.profile-location {
  color: #888;
  font-size: 1rem;
}

.profile-info {
  margin-bottom: 2rem;
}

.info-section {
  margin-bottom: 1.5rem;
  padding-bottom: 1.5rem;
  border-bottom: 1px solid #eee;
}

.info-section:last-child {
  border-bottom: none;
}

.info-section h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 1rem;
}

.edit-profile-btn {
  display: block;
  width: 100%;
  padding: 0.8rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 1rem;
}

.edit-profile-btn:hover {
  background-color: #3a7bc8;
}

.parking-records-section {
  margin-top: 2rem;
}

.parking-records-section h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 1rem;
}

.no-records {
  color: #888;
  font-style: italic;
}

.parking-records-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.parking-record-item {
  padding: 12px;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.parking-record-item.clickable {
  cursor: pointer;
  transition: transform 0.2s, box-shadow 0.2s;
}

.parking-record-item.clickable:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.parking-record-item.active {
  border-left: 4px solid #4caf50;
}

.parking-record-item.completed {
  border-left: 4px solid #2196f3;
}

.parking-record-item.cancelled {
  border-left: 4px solid #f44336;
}

.record-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.record-header h4 {
  font-size: 1.1rem;
  margin: 0;
}

/* 通用按钮样式 */
.cancel-btn, .view-btn, .review-btn {
  padding: 0 8px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: center;
  border: none;
  min-width: 60px;
  height: 24px;
  line-height: 24px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.review-btn {
  margin-top: 6px;
  background-color: #ff9800;
  color: white;
  align-self: flex-end;
}

.review-btn:hover {
  background-color: #f57c00;
}

.cancel-btn {
  background-color: #ff4d4f;
  color: white;
}

.cancel-btn:hover {
  background-color: #ff7875;
}

.view-btn {
  background-color: #1890ff;
  color: white;
}

.view-btn:hover {
  background-color: #40a9ff;
}

/* 状态标签样式 */
.status {
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 12px;
  color: white;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 54px;
  min-height: 20px;
}

.status.pending {
  background-color: #faad14;
}

.status.confirmed {
  background-color: #52c41a;
}

.status.active {
  background-color: #1890ff;
}

.status.completed {
  background-color: #52c41a;
}

.status.cancelled {
  background-color: #d9d9d9;
  color: #666;
}

.record-details {
  margin-top: 6px;
  margin-bottom: 6px;
  font-size: 12px;
  color: #555;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
}

.record-details p {
  margin: 2px 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.record-details strong, .reservation-details strong {
  font-weight: 600;
  color: #444;
  margin-right: 2px;
}

.record-review-section {
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.payment-method-info {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
}

.card-info {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.card-brand {
  font-weight: 600;
  text-transform: capitalize;
}

.update-payment-btn,
.setup-payment-btn {
  padding: 0.5rem 1rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.update-payment-btn:hover,
.setup-payment-btn:hover {
  background-color: #3a7bc8;
}

.no-payment-method {
  text-align: center;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 8px;
}

.coupons-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.coupon-item {
  display: flex;
  padding: 1rem;
  background-color: #f5f5f5;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.coupon-amount {
  flex: 0 0 120px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #4a90e2;
  color: white;
  font-weight: 600;
  padding: 0.5rem;
  border-radius: 4px;
  margin-right: 1rem;
}

.coupon-info {
  flex: 1;
}

.coupon-description {
  margin: 0 0 0.5rem 0;
  font-weight: 500;
}

.coupon-expiry {
  margin: 0;
  font-size: 0.8rem;
  color: #888;
}

.balance-info {
  background-color: #f5f5f5;
  padding: 1rem;
  border-radius: 8px;
}

.gift-balance,
.actual-balance,
.total-balance {
  font-weight: 600;
  color: #4a90e2;
}

.top-up-button {
  margin-top: 1rem;
  padding: 0.5rem 1rem;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.top-up-button:hover {
  background-color: #3a7bc8;
}

.loading {
  text-align: center;
  padding: 2rem;
  font-size: 1.2rem;
  color: #666;
}

.error-message {
  background-color: #ffebee;
  color: #c62828;
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .profile-container {
    padding: 1rem;
  }
  
  .coupon-item {
    flex-direction: column;
  }
  
  .coupon-amount {
    margin-right: 0;
    margin-bottom: 0.5rem;
  }
}

.transactions-section {
  margin-top: 2rem;
}

.transactions-section h3 {
  font-size: 1.3rem;
  color: #333;
  margin-bottom: 1rem;
}

.transactions-list {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.transaction-item {
  padding: 1rem;
  border-radius: 8px;
  background-color: #f9f9f9;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
}

.transaction-item:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.transaction-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.transaction-header h4 {
  font-size: 1.1rem;
  margin: 0;
  color: #2c5282;
}

.transaction-details {
  font-size: 0.9rem;
  color: #555;
}

.transaction-details p {
  margin: 0.3rem 0;
}

.transaction-item .error-message {
  color: #c62828;
  font-size: 0.85rem;
  margin-top: 0.5rem;
  padding: 0.5rem;
  background-color: #ffebee;
  border-radius: 4px;
}

/* 添加丢失的交易状态样式 */
.transaction-item .status {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 54px;
  min-height: 20px;
}

.transaction-item .status.succeeded {
  background-color: #e8f5e9;
  color: #2e7d32;
}

.transaction-item .status.failed {
  background-color: #ffebee;
  color: #c62828;
}

.transaction-item .status.pending {
  background-color: #fff3e0;
  color: #ef6c00;
}

/* 预约部分的样式 */
.reservations-section {
  margin-top: 24px;
  padding: 20px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.reservations-section h3 {
  margin-top: 0;
  margin-bottom: 16px;
  color: #333;
  font-size: 18px;
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 12px;
}

.reservations-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.reservation-item {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 10px;
  border-left: 4px solid #1890ff;
  position: relative;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
}

.reservation-item.cancelled {
  border-left-color: #d9d9d9;
  opacity: 0.7;
}

.reservation-item.pending {
  border-left-color: #faad14;
}

.reservation-item.confirmed {
  border-left-color: #52c41a;
}

.reservation-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.reservation-header h4 {
  margin: 0;
  font-size: 16px;
  color: #333;
  max-width: 75%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.reservation-details {
  margin-bottom: 6px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
}

.reservation-details p {
  margin: 2px 0;
  color: #666;
  font-size: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.reservation-actions {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
  margin-top: 6px;
}

/* 响应式调整 */
@media (max-width: 768px) {
  .reservation-header {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .reservation-header h4 {
    margin-bottom: 8px;
    max-width: 100%;
  }
  
  .reservation-item {
    padding: 12px;
  }
  
  .reservation-details {
    grid-template-columns: 1fr;
  }
  
  .reservation-actions {
    justify-content: flex-start;
  }
}

@media (max-width: 400px) {
  .reservation-details, .record-details {
    grid-template-columns: 1fr;
  }
  
  .reservation-details p, .record-details p {
    white-space: normal;
  }
}