.parking-timeline {
  margin: 20px 0;
  padding: 16px;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
}

.timeline-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.timeline-legend {
  display: flex;
  gap: 16px;
  margin-bottom: 12px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 6px;
  font-size: 12px;
  color: #666;
}

.legend-color {
  width: 12px;
  height: 12px;
  border-radius: 2px;
}

.legend-color.reserved {
  background-color: #faad14;
}

.legend-color.available {
  background-color: #52c41a;
}

.legend-color.passed {
  background-color: #bfbfbf;
}

.legend-color.unavailable {
  background-color: #f5f5f5;
}

.timeline-container {
  margin: 20px 0;
}

.timeline-bar {
  width: 100%;
  height: 6px;
  background: #f0f0f0;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.timeline-progress {
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  background: #52c41a;
  border-radius: 3px;
  transition: width 0.3s ease;
}

.timeline-progress.reserved {
  background: #faad14;
}

.timeline-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  color: #666;
  font-size: 12px;
}

.timeline-slot.passed {
  background-color: #d9d9d9;
  opacity: 0.6;
}

.timeline-content {
  margin: 16px 0;
}

.timeline-hours {
  display: flex;
  width: 100%;
  height: 40px;
  position: relative;
  overflow-x: auto;
  padding-bottom: 20px;
}

.hour-mark, .time-mark {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  min-width: 30px;
}

.hour-block, .time-block {
  width: 100%;
  height: 24px;
  background-color: #52c41a;
  border-right: 1px solid #fff;
  transition: all 0.2s ease;
}

.time-block {
  height: 20px;
  border-radius: 2px;
  margin: 0 1px;
  cursor: pointer;
}

.time-block:hover {
  transform: scaleY(1.1);
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}

.time-block.available:hover {
  background-color: #389e0d;
}

.hour-block.reserved, .time-block.reserved {
  background-color: #faad14;
  cursor: not-allowed;
}

.hour-block.available, .time-block.available {
  background-color: #52c41a;
}

.hour-block.passed, .time-block.passed {
  background-color: #bfbfbf;
  cursor: not-allowed;
}

.hour-block.unavailable, .time-block.unavailable {
  background-color: #f5f5f5;
  cursor: not-allowed;
}

.hour-label {
  position: absolute;
  bottom: -20px;
  font-size: 12px;
  color: #666;
}

.timeline-info {
  margin-top: 24px;
  text-align: center;
  color: #666;
  font-size: 13px;
}

.timeline-info div {
  margin: 4px 0;
}

/* 自定义滚动条样式 */
.timeline-container::-webkit-scrollbar {
  height: 6px;
}

.timeline-container::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 3px;
}

.timeline-container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 3px;
}

.timeline-container::-webkit-scrollbar-thumb:hover {
  background: #555;
} 